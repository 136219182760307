import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import BenefitsWithIconList from "../components/Common/BenefitsWithIconList";
import CollocationServersHero from "../components/ColocationServers/ColocationServersHero";
import CollocationsServices from "../components/ColocationServers/CollocationsServices";
import RequestOffer from "../components/ColocationServers/RequestOffer";
import SimpleFAQS from "../components/Common/SimpleFAQS";
import SEO from "../components/App/SEO";

// SVG
import DollarSvg from "/src/assets/svgComponents/dolar-circle.svg";
import SaveSvg from "/src/assets/svgComponents/save.svg";
import RefreshServerSvg from "/src/assets/svgComponents/server-refresh.svg";
import SvgChat from "/src/assets/svgComponents/chat.svg"
import SvgInfinite from "/src/assets/svgComponents/inifinite.svg"
import SvgRaks from "/src/assets/svgComponents/raks.svg"
import SvgRound from "/src/assets/svgComponents/round.svg"
import SvgClime from "/src/assets/svgComponents/clime.svg"
import SvgConfig from "/src/assets/svgComponents/config.svg"


const advantagesList = [
    {
        title: 'Costuri eficientizate',
        svgImg: <DollarSvg/>,
        description: 'Te bucuri de cheltuieli de întreținere și operare reduse substanțial pentru data centerul pus la dispoziția ta prin serviciul de colocare servere oferit de SiteBunker.ro.'
    },
    {
        title: 'Sysadmin suport 24/7',
        svgImg: <SvgChat/>,
        description: 'Cu o experiență de peste 16 ani în domeniul IT, transfer şi securitate a datelor, echipa de sysadmini SiteBunker.ro este pregătită să te asiste în orice provocare tehnică.'
    },
    {
        title: 'Data Center ce respectă Tier III',
        svgImg: <RefreshServerSvg/>,
        description: 'Te vei bucura de avantajele unui data center optimizat pentru funcţionarea în parametrii optimi privind climatizarea, energia electrică neîntreruptă şi conexiunea la internet redundantă.'
    },
    {
        title: 'Sinergie și scalabilitate',
        svgImg: <SaveSvg/>,
        description: 'Indiferent de dinamica afacerii tale, îți punem la dispoziție soluțiile potrivite, care să țină pasul cu evoluția ta, totul fiind optimizat inclusiv în parametrii financiari stabiliți.'
    }
]
const qualitiesList = [
    {
        title: 'Montaj și configurare',
        svgImg: <SvgConfig/>,
        description: 'Vei beneficia de toate resursele SiteBunker.ro pentru a monta, configura și testa serverele sau sistemele data centerului pus la dispoziția ta.'
    },
    {
        title: 'Asistență non-stop',
        svgImg: <SvgChat/>,
        description: 'Monitorizarea și intervenția este asigurată non-stop la SiteBunker.ro prin echipa noastră dedicată de sysadmini cu peste 16 ani experiență.'
    },
    {
        title: 'Electricitate și climatizare',
        svgImg: <SvgClime/>,
        description: 'Fiecare rack este alimentat de două circuite separate (UPS și generator backup), respectiv sisteme de climatizare şi ventilaţie redundante.'
    },
    {
        title: 'Conectivitate continuă',
        svgImg: <SvgInfinite/>,
        description: 'Sunt disponibile 3 linii de conectivitate la internet, asigurate de provideri naționali și internaționali diferiți, având peering-uri de peste 100 Gb/s.'
    },
    {
        title: 'Rack-uri și networking',
        svgImg: <SvgRaks/>,
        description: 'Te bucuri de performanța echipamentelor conectate la routere CISCO de ultimă generație și de servere în rack-uri Schrack și Eaton de 42U sau 47U.'
    },
    {
        title: 'Securitate și firewall',
        svgImg: <SvgRound/>,
        description: 'Beneficiezi de protecţie împotriva atacurilor DDoS şi Flood prin soluţii de analizare permanentă a traficului la nivel de „packet” prin Voxility și Arbor.'
    },
    {
        title: 'Solicită ofertă',
        svgImg: <SvgChat/>,
        description: 'Răspundem prompt la orice solicitare de ofertă colocare servere.'

    }
]
const faqs = [
    {
        title: 'De ce să alegi serviciul de colocare servere?',
        text1: 'Prin colocarea unui server, practic accepți să găzduiești propriul server într-un data center administrat de către SiteBunker.ro, fără a mai avea griji legate de mediul securizat, alimentarea cu energie electrică sau conexiunea la internet, costurile cu echipa tehnică sau detalii tehnice legate de climatizare și ventilație.\n',
    },
    {
        title: 'Ce trebuie să fac înainte de a coloca un server?',
        text1: 'Echipa SiteBunker.ro te va informa cu privire la toate detaliile pe care va fi nevoie să le pregătești înainte de a aduce serverul în data centerului nostru. ' +
            'Ideal este să instalezi pe server sistemul de operare pentru a te putea conecta remote la acesta și să configurezi setările IP pentru a urgenta punerea în funcțiune.',
    },
    {
        title: 'Care este numărul minim de servere acceptat pentru colocare?',
        text1: 'Tu alegi numărul de servere pentru colocarea la SiteBunker.ro. Nu există nicio limită maximă. Poți începe cu minim un server.',
    },
    {
        title: 'Cum se face accesul în datacenterul SiteBunker.ro?',
        text1: 'Accesul este disponibil 24/7 în sistem remote, astfel încât să poți face orice monitorizare sau administrare în orice moment al zilei, din orice locație de către sysadminii noștri. ' +
            'De asemenea, echipa de sysadmini SiteBunker.ro este disponibilă să te asiste ori de câte ori vei avea nevoie.',
    },
    {
        title: 'Ce să aleg? Un server dedicat și colocare server?',
        text1: 'Diferența între un server dedicat și unul colocat este dată de faptul că, în cazul colocării tu ești unicul proprietar al hardware-ului, fapt ce îți asigură un control total asupra sistemului de operare instalat și specificațiile preferate. Pe termen lung, colocarea poate fi mult mai avantajoasă față de închirierea unui server dedicat de hosting.',
    },
    {
        title: 'Sunt furnizor de găzduire. Pot coloca servere la SiteBunker.ro?',
        text1: 'Răspunsul este DA. Inclusiv furnizorii de găzduire pot coloca propriile servere în datacenterul SiteBunker.ro.',
    },
    {
        title: 'Mai ai și alte întrebări suplimentare?',
        text1: 'Este absolut normal să ne adresezi orice întrebare suplimentară legată de colocare. Poți intra în legătură directă cu echipa SitBunker.ro la orice oră din zi sau din noapte.',
    }
]


const CollocationServers = () => {
    return (
        <Layout>

            {/*SEO*/}
            <SEO title="Ești interesat de colocare servere hosting? SiteBunker.ro te ajută!"
                 description="La SiteBunker.ro ai la dispoziție posibilități multiple de colocare servere hosting care să-ți asigure scalabilitatea și eficiența oricărui proiect big data."
            />

            {/*Navbar*/}
            <Navbar/>

            {/*Hero*/}
            <CollocationServersHero/>

            {/*Services*/}
            <CollocationsServices/>

            {/*Advantages*/}
            <BenefitsWithIconList title='Avantajele oferite de colocare servere' dataList={advantagesList}
                                  isGreyBackground={false}/>

            {/*Quality Services*/}
            <BenefitsWithIconList title='Colocare servere eficientă la costuri reduse'
                                  subTitle='Arhitectură redundantă și tehnologii de ultimă generație, toate la dispoziția ta prin data centerul SiteBunker.ro'
                                  dataList={qualitiesList} isGreyBackground={true}/>

            {/*RequestOffer*/}
            <RequestOffer/>

            {/*CollocationFAQS*/}
            <SimpleFAQS title='Află totul despre colocare servere'
                        subTitle='Am pregătit o listă cu cele mai frecvente întrebări și răspunsuri în secțiunea de mai jos.'
                        questionsData={faqs}/>

            {/*Footer*/}
            <Footer/>
        </Layout>
    )
}

export default CollocationServers