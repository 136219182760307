import React from "react";
import '/src/assets/css/CollocationServers/collocation-servers-hero.css'

import checkedImg from '/src/assets/images/collocation-servers/checked-box.png'
import collocationHero from '/src/assets/images/collocation-servers/collocation-servers-hero.png'
import {AnchorLink} from "gatsby-plugin-anchor-links";


const CollocationServersHero = () => {

    return (
        <section id='collocation-servers-hero'>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-lg-7 col-12">

                        {/*Header*/}
                        <div className="row">
                            <div className="col-12 mb-4">
                                <h2 className='mb-4 h2-title'>Colocare servere</h2>
                                <p>Vrei ca serverul tău de hosting să fie găzduit într-un datacenter performant, care
                                    să-ți asigure un nivel ridicat de independență și control? Iată ce îți pune la
                                    dispoziție SiteBunker.ro:</p>
                            </div>
                        </div>


                        {/*Specs*/}
                        <div className="row mb-5">
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Datacenter ce respecta Tier III</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Peering-uri de peste 100Gb/s</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>UPS-uri APC, Infosec si Eaton</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Generatoare Senci și Energo</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Climatizare in row N+1</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>SLA 99.99% Uptime</span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                                <img src={checkedImg} className='img-fluid mr-2' alt="Checked" loading="lazy"/>
                                <span>Acces securizat biometric</span>
                            </div>
                        </div>

                        {/*Buttons*/}
                        <div className="row">
                            <div className="col-6">
                                <AnchorLink
                                    to="/colocare-servere#request-offer"
                                    title="Solicită ofertă"
                                    className="main-blue-btn"
                                    stripHash
                                />
                            </div>
                            <div className="col-6">
                                <AnchorLink
                                    to="/colocare-servere#benefits-with-icon"
                                    title="Află detalii"
                                    className="view-more-button"
                                    stripHash
                                />
                            </div>
                        </div>
                    </div>

                    {/*Hero Img*/}
                    <img src={collocationHero} className='img-fluid hero-img' alt="Collocation Hero" loading="lazy"/>

                </div>
            </div>
        </section>
    )
}

export default CollocationServersHero