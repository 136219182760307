import React from "react";
import '/src/assets/css/CollocationServers/collocations-services.css'
import {Link} from "gatsby";

const CollocationsServices = () => {
    return (
        <section id='collocations-services'>
            <div className="container">
                <div className="row text-center">
                    <div className="col-12 text-center">
                        <h3 className='h3-title text-white'>Colocare servere full-service</h3>
                        <p className='text-white mb-5'>Cu serviciile de colocare servere de la SiteBunker.ro te bucuri
                            de performanţă sporită pentru toate echipamentele tale IT.</p>

                        <p className='text-white'>Data centerul nostru este pregătit să găzduiască orice fel de
                            echipamente rackabile: servere, storage NAS-uri, switch-uri, routere, de la 1U până la 42U
                            per rack.
                        </p>

                        <p className='text-white'>
                            Pe lângă suportul gratuit şi accesul 24/7, puse la dispoziție de către echipa noastră de
                            sysadmini, te vei bucura de performanța asigurată de tehnologia enterprise-grade de ultimă
                            generație, inclusiv de alimentarea cu energie electrică supradimensionată, chiar și de
                            soluții performante de răcire și ventilație.
                        </p>

                        <p className='text-white'>Astfel, întreaga ta infrastructură IT va fi în siguranță și ușor de
                            administrat, în timp ce costurile cu exploatarea data centerului vor cădea exclusiv în
                            sarcina SiteBunker.ro, astfel încât tu să te bucuri de performanță, dar și de cost
                            efficiency.</p>
                    </div>

                    <div className="d-flex justify-content-center w-100">
                        <Link to={'/contact'} className='main-blue-btn w-100 mt-4'>Contactează-ne</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CollocationsServices