import React from "react";
import BenefitInfo from "./BenefitInfo";

import "/src/assets/css/Common/benefits-with-icon.css"

const BenefitsWithIconList = ({title, subTitle, dataList, isGreyBackground}) => {

    return (

        <section id='benefits-with-icon' className={isGreyBackground ? 'grey-bg' : ''}>
            <div className="container">
                <div className="register-header text-center">
                    {title ? <h3 className='main-color mb-0 h3-title'>{title}</h3> : ''}
                    {subTitle ? <p className='main-color mb-0'>{subTitle}</p> : ''}
                </div>

                <div className="row">
                    {
                        dataList.map(benefit => (
                            <div className="col-md-6 col-12">
                                <BenefitInfo img={benefit.img} imgAlt={benefit.altImg}
                                             svgIcon={benefit.svgImg}
                                             title={benefit.title}
                                             description={benefit.description}
                                />
                            </div>
                        ))
                    }
                </div>

            </div>
        </section>
    )
}

export default BenefitsWithIconList